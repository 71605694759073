$primary-color: #2196f3;
$success: #2abe77;
$grey-back: #F0F0F0;

$blue-dim: #ebf3fe;

$blue-light: #f3f6f9;

$border-color: #bdbdbd;

$warning: #ffae00;
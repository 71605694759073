.contacts_container {
    padding: 20px;
    padding-top: 0;
    
        .contacts_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .filter_section {
                display: flex;

                .form_selection_text {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;
                }
            }
        }
}

.contact-export-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
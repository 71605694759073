.notfound{
    text-align: center;

    &__heading{
        font-size: 60px;
        color: #222222;
    }

    &__content{
        font-size: 14px;
        color: #666666;
    }

    &__link {
        color: #2067ff;
    }
}
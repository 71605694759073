
.staff_wrapper {
    max-width: 800px;
    padding: 5px;
}

.staff_paper {
    margin: 0 15px;
    max-width: 800px;
}

.staff_list {
    padding: 0 !important;
}

.list_item {
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #f5f5f5;
    }
}

.add_staff {
    margin-top: 15px;
    margin-left: 15px;
}


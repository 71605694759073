.buttonBox{
    text-align: right;
    margin: 0 20px 20px;
}

.code-formater {
    height: 290px;
    white-space: pre-wrap;
    overflow-x: hidden;
}

.code-snippet {
    font-size: 14px;
    font-weight: 500;
    background-color: #e7edf3;
    padding: 15px;
    border-radius: 6px;
    overflow: scroll;
}

p.form-wc {
    @extend .warning-container;
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

button.add-option-btn {
    margin-top: 5px;
}

div.form_submit__error {
    display: inline;
    margin-left: 15px;
}

div.form_submit__info {
    display: inline-block;
    margin-top: 15px;
    background-color:#f0f2f3;
    border-radius:6px;
    padding: 10px;
    text-align: left;
    margin-right: 15px;
}

div.each-file-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}
.form-tab {
    text-transform: none;
    min-width: 72px;
    font-weight: 500;
    margin-right: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    &:hover {
        color: #2196f3;
        opacity: 1;
    }
    .selected {
        color: #2979ff;
        font-weight: 500;
    }
    &:focus {
        color: #2979ff;
    }
}
.form-tabs {
    border-bottom: 1px solid #e8e8e8;
    .indicator {
        background-color: #2979ff;
    }
}
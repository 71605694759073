.success-container {
    background-color:#DCFCE7;
	border:1px solid #166534;
	border-radius:6px;
	padding:10px;
}

.error-container {
    background-color:#fef2f3;
	border:1px solid #fcd9dc;
	border-radius:6px;
	padding:10px;
}

.warning-container {
    background-color:#fffbe7;
    border:1px solid #dbc906;
    color:#000000;
    border-radius:6px;
    padding: 15px;
}

p.error-text {
    color: #ff0000;
}

div.empty-message {
    background-color:#f0f2f3;
    border:1px solid #d5d5d5;
    border-radius:6px;
    padding: 15px;
    text-align: center;
    margin: 15px 0;
    font-size: 14px;
}

.link_style {
    color: #00a2ff;
}

.page-access-error {
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background: #ffcad4;
    width: fit-content;
    margin: 150px auto !important;
}

.full-width-textfield {
    width: 100%;
}

.select-under-head {
    max-height: 40px;
    margin-top: 10px;
}

div.center-text {
    text-align: center;
    justify-content: center;
}

a.go-to-link {
    display: block;
    margin-top: 15px;
}

p.marginT15 {
    margin-top: 15px;
}

p.icon-text {
    margin-left: 5px;
}

.dialog-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px 24px;

    .dialog-title-close-btn {
        padding: 0;
        height: 20px;
    }
}

.success__color {
    color: $success;
}

.warning__color {
    color: $warning;
}

.box__elevated {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12);
}

.lp__holder {
    height: 3px;
}

.grid_pad_x {
    padding: 16px;
}

.grid_pad_xs {
    padding: 4px;
}

.wid_min_120 {
    min-width: 120px;
}

.wid_100 {
    width: 100px;
}

.hide-scrollbars {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.more-information {
    background: #f7f7f7;
    padding: 10px 10px 10px 20px;
    border-radius: 6px;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 3px;
        background-color: #e1e1e1;
        top: 10px;
        bottom: 10px;
        left: 10px;
        border-radius: 6px;
    }
}
.contact-account-title {
    font-weight: 500 !important;
    font-size: 18px;

}
.app-key-warning {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.settings_section {
    margin: 15px 0 !important;
    font-size: 16px !important;
}


.list_paper {
    max-width: 800px;
}

.keys_container {
    padding: 15px;

    .key_head_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }

        .key_head {
            margin-top: 15px;
    
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .key_plate {
        padding: 15px;
        word-wrap: break-word;
        border-radius: 10px;
        background-color: $grey-back;
    }

    .key-add-steps {
        position: relative;
        padding: 0 15px;
        margin-top: 25px;

        &::before {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 3px;
            border-radius: 6px;
            background-color: #4caf50;
        }
    }
}

.settings_list {
    padding: 0 !important;
}

.list_item {
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;

  &:last-child {
      border-bottom: none;
  }

  &.active {
      background-color: $blue-dim;
  }
}

.role_change_header {
    display: flex;
    justify-content: right;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    // margin-bottom: 15px;
}

.role_list_wrapper {
    display: flex;
    flex-direction: row;
}

.rights_panel {
    min-width: 250px;
}

.right_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog_title {
    padding-left: 0 !important;
    border-bottom: 1px solid #e0e0e0;
}

.role_add_btn_wrapper {
    text-align: center;

    .role_add_button {
        margin-top: 10px !important;
        margin-bottom: 2px !important;
    }
}

.role_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;

    .role_name {
        margin-left: 10px;
        margin-top: 18px;
        margin-bottom: 18px;
        width: 200px;
    }

    .save_cancel_wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
}

// Deals pipeline in the settings page.
.pipeline_card {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    .pipeline_contents {
        display: flex;
        justify-content: space-between;
    }

    .pipeline_list_wrapper {
        padding: 0;
        margin-bottom: 16px;
    }
}

.pipeline_add_button_wrapper {
    justify-content: center !important;
}

.stages_add_wrapper {
    margin-top: 15px !important;
}

.ca-details-cont {
    width: 400px;
    padding-bottom: 15px;

    .c-account-card1 {
        margin: 15px 0;
    }

    .c-account-card {
        padding: 15px;
        display: flex;
        justify-content: space-between;

        .c-a-card-first {
            width: 100%;
            padding-right: 15px;
        }
    }

    .address-add-wrapper {
        text-align: center;

        .address-add-btn {
            padding: 0;
        }
    }

    

    .address-form-card {
        padding: 15px;
    }
}

.email-replace-dialog {
    width: 100%;
    margin: 0 auto;

    .emailDataWrapper {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e0e0e0;
    }
        
    .email-r-form-label {
        display: block;
    }

    .email-replace-input {
        width: 100%;
    }
}
        
p.email_change_message {
    background-color: #f2f8f1;
    word-wrap: break-word;
    margin-bottom: 15px;
    text-align: justify;
    padding: 15px;
    border-radius: 6px;
}
        
button.email-update-btn {
    margin-top: 15px;
}
.settings-tabs {
    border-bottom: 1px solid #e8e8e8;
    .indicator {
        background-color: #2979ff;
    }
}
.settings-tab {
    margin-right: 32px !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    min-width: 72px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    &:hover {
        color: #2196f3;
        opacity: 1;
    }
    .selected {
        color: #2979ff;
        font-weight: 500;
    }
    &:focus {
        color: #2979ff;
    }
}
.settings-main {
    flex-grow: 1;
}

.domain_add_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/base";
@import "base/utilities";

@import "layout/grid";

@import "components/notfound";

@import "pages/login";
@import "pages/home";
@import "pages/campaigns";
@import "pages/customers";
@import "pages/loyaltyperformance";
@import "pages/redeemingunits";
@import "pages/referrals";
@import "pages/referralsperformance";
@import "pages/reports";
@import "pages/settings";
@import "pages/tiersperformance";
@import "pages/triggeredemails";
@import "pages/viptiers";
@import "pages/profile";
@import "pages/Forms";
@import "pages/createForm";
@import "pages/EditForm";
@import "pages/Leads";
@import "pages/leadDetails";
@import "pages/settings";
@import "pages/staffs";
@import "pages/staffForm";
@import "pages/Contacts";
@import "pages/Deals";
@import "common";
@import "pages/Email";
@import "pages/AddressForm";
@import "pages/ConfirmEmail";
@import "pages/Docs.scss";
@import "pages/Manage";
@import "pages/ContactAccounts";
@import "pages/ChangeLog";
@import "pages/Main";
@import "pages/Sidebar.scss";
@import "pages/SidebarNav.scss";
.noti-popper {
    z-index: 1201;
    width: 320px;
    max-height: 320px;

    .noti-list {

        .notis-container {
            max-height: 320px;
            overflow-y: scroll;

            .noti-wrapper {
                padding: 10px 15px 0 15px;
                border-bottom: 1px solid #eeeeee;

                &:hover {
                    background-color: #f3f2f2;
                }
            }

            .each-clear-btn {
                padding: 0;
            }

            .empty-noti-msg {
                padding: 10px 15px 0 15px;
            }

            .each-noti-msg {
                margin-bottom: 10px;

                &.link-noti {
                    display: block;
                    border: 1px solid #c9e3f7;
                    border-radius: 10px;
                    padding: 5px 8px;
                    background-color: #f0f8ff;

                    &:first-child {
                        // margin-top: 0;
                    }
                }
            }

            .noti-datetime {
                text-align: right;
                padding-bottom: 10px;
                display: flex;
                justify-content: space-between;
            }
        }

        .noti-tools {
            display: flex;
            justify-content: right;
            padding: 10px;

            .noti-clr-btn {
                padding: 0;
            }
        }
    }
}

.acc-popper {
    z-index: 1201;
    min-width: 200px;
    max-height: 320px;
    margin-top: 5px;
    padding-top: 5px;

    .acc-list {
        padding: 15px;
    }
}


.chart-fltr-tls {
    display: flex;
    justify-content: right;
    padding: 15px 0 15px 0;
}

.pre-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chart-tooltip {
    background-color: #FFF;
    opacity: .9;
    padding: 10px;
    border-radius: 4px;
}

.progress-timeout {
    display: flex;
    justify-content: center;
    padding: 15px;
}

p.cl-list-item {
    margin-bottom: 15px;
}

.log-nav-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;

    .log-nav-wrapper {
        display: flex;
        justify-content: right;
    }
}

div.date_select {
    margin-left: 15px;
}

div.export_info {
    margin-bottom: 15px;
}

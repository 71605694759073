.email-confirm-wrapper {
    max-width: 400px;
    margin: 0 auto;

    .email-confirm-content {
        @extend .auth_paper;
        display: flex;
        flex-direction: column;
        align-items: center;

        .success-color {
            color: $success;
        }
    }
}
.log-l-cont {
    @extend .box__elevated;

    ul.log-list {
        padding-bottom: 0;

        .bu-logo {
            margin-left: 10px;
        }

        .size-n-create {
            display: grid;
            row-gap: 3px;
        }

        .restore-bu {
            position: relative;

            .restore-lp {
                position: absolute;
                bottom: 0px;
                width: 100%;
            }
        }
    }
}

.bu-change {
    display: flex;
    flex-direction: row;
    
    .bu-action1 {
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .auto-bu-select {
            margin: 0 15px;

            & > div {
                min-width: 120px;
            }

            .select-progress {
                position: absolute;
                bottom: 5px;
            }
        }
    }

    .bu-action2 {
        padding: 5px 0;
        margin-left: 15px;
        position: relative;

        .btn-progress {
            position: absolute;
            bottom: 5px;
            width: 100%;
        }
    }
}

div.campaigns-page {
  margin: 0;
  padding: 30px;
  h6 {
    font-weight: 400;
    padding: 5px 0 0 15px;
  }
  h4 {
    font-weight: 400;
    padding: 10px 0 40px 0;
    font-size: 18px;
  }
  h5 {
    display: inline;
  }
  svg.MuiSvgIcon-root:hover {
    transform: rotate(135deg);
  }
  div.MuiPaper-root {
    padding: 15px 0;
  }
}




.MuiInputBase-input{
	/* border:1px solid #3B82F6 !important;*/
	border-radius:4px;	
}

.input_style{
	// border:1px solid #3B82F6 !important;
	border-radius:4px;
}

.MuiOutlinedInput-inputMarginDense{
	// height:30px !important;
}



.MuiButton-containedPrimary{
	// background-color: #3B82F6 !important;
	// height:50px !important;
}

.MuiTableCell-footer {
	border: none !important;
}

@media only screen and (min-width: 1280px) {
	.logo_bdr{
		border-right:1px solid #ffffff;
		padding-right:118px;
	}
}

.logodiv{
	width:100%;
	/* margin-top:80px;*/
	Text-align:center;
}

.auth{
    min-height: calc(100vh - 200px);   
    background-repeat:no-repeat;
	background-position:center;
}


.auth__form {
    width: 100%;
    text-align: center;
	border-top: 1px solid #3B82F6;
    // margin-top: 20px;
    padding-top: 10px;

	&.loading__auth {
		opacity: .7;
		pointer-events: none;
	}

	.auth__form__button {
		margin: 16px 0px;
	}

	.btn-col {
		display: grid;
	}
}

.auth__form__group{
	// border-bottom: 1px solid #3B82F6;
	padding-bottom:8px;
}

.auth_paper {    
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	border:1px solid #3B82F6;
	border-radius:20px !important;

	.lp-login {
		width: 100%;
	}
}

.auth__title {
	// font-size: 1.5rem;
	font-weight: 400;
}

h6.auth__lead {
	// font-size: 1rem;
	font-weight: 400;
	padding-top: 10px;
	text-align: center;
	margin-bottom: 20px;
	line-height: 24px;

	.value__chip {
		font-weight: bold;
		padding: 0 5px;
		border: 1px solid #e0e0e0;
		border-radius: 10px;
	}
}

.password_fields_wrapper {
	text-align: center;
	border-top: 1px solid #3B82F6;
}

div.cred_input_textfield {
	width: 100%;
	margin-top: 25px;
}

.change_password_btn {
	width: 100%;
}


.password_sent_message {
	@extend .success-container;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.l-signuptxt{
	font-family:roboto;
	font-size:18px;
	color:#2A2A2A;
}

.l-signuptxt a{
	text-decoration:underline;
}

.copyright{
	font-family:roboto;
	font-size:13px;
	color:#2A2A2A;
	margin-top:20px;
	text-align:center;
}

.password_sent_error {
    @extend .error-container;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.more-option-wrapper {
    display: flex;
    justify-content: space-between;

    .forget-password-button {
        color: #1877f2;
        font-weight: 400;
        padding: 0;
    }
}


@media (min-width: 0px){
	.MuiContainer-maxWidthXs {
		max-width: 475px !important;
	}
}


/* Dashboard */

.makeStyles-footStyle-18{
	border-radius:6px;
	margin-bottom:15px;
}

.card-container{
	display: flex;
	padding:25px;
}

div.card-container-common {
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68,0.15);
	border-radius: 6px;
}

.card-count{
	font-size:30px;
}

.card-container > div{
	font-family:roboto;
	font-size:14px;
	font-weight:bold;
	margin-left:20px;
}

.page_t_head {
	padding: 12px 20px 0px 20px;

	.lp-deals {
		height: 3px;
	}
}

.page_d_wrapper {
	display: flex;
	justify-content: space-between;
	padding-bottom:20px;
	align-items: center;
}

.page_t_wrapper {
	display: flex;
	justify-content: space-between;
	padding-bottom:20px;
	border-bottom:1px solid #eeeeee;
	align-items: center;
	margin-bottom: 20px;
	.docs-page-title {
		margin-left: 240px;
	}
}

.page_title{
	color:#000000 !important;
	font-size: 24px !important;
	font-weight: bold !important;
	line-height: 21px !important;

}


.creative-container{
	display: flex;
	padding:25px;
	background-color: $blue-dim;
	border:1px solid #3B82F6;
	border-radius:16px;
}

.creative-container > div{
	font-family:roboto;
	font-size:18px;
	font-weight:bold;	
	padding:15px 0px 10px 20px;
}

.txt-cntnr{
	font-weight:normal !important;
	font-size:15px;
	padding-top:12px;
	
}

.chrt-txy-wrpr {
	padding: 20px 20px 0 20px;
	
	.chartTxt{
		color:#000000;
		font-size:18px;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 10px;
	}
}

/* Forms */

.tble-bdr{
	border-radius:10px !important;
	background-color:#fbfdff !important;
	border:1px solid $blue-dim;
}

.tble-bdr  .tss-1qtl85h-MUIDataTableBodyCell-root {
	font-size: 14px;
}
.tble-bdr  th {
	font-size: 14px;
}



.tble-on-load {
	table {
		opacity: .7;
		pointer-events: none;
	}
}

button.btn-style {
	color:#ffffff;
	border-radius: 4px;
	background-color:#2196f3;
	font-size: 14px;
}

button.bdr-btn-style-blue {
	color:#0C48AA;
	border-radius:4px;
	padding:0 20px;
	background-color:#D6E7FF;
	font-size:12px;
	border:1px solid #2196f3;
	height: 24px;
	line-height: 24px;
}

button.bdr-btn-style-green {
	color:#166534;
	border-radius:4px;
	padding:0 20px;
	background-color:#DCFCE7;
	font-size:12px;
	border:1px solid #166534;
	height: 24px;
	line-height: 24px;
}

button.bdr-btn-style-chu {
	color:#9f0909;
	border-radius:4px;
	padding:0 20px;
	background-color:#fbbcbc;
	font-size:12px;
	border:1px solid #EF4444;
	height: 24px;
	line-height: 24px;
}

button.bdr-btn-style-white {
	color:#4b4b4b;
	border-radius:4px;
	padding:0 5px;
	background-color:#e8e8e8;
	font-size:12px;
	border:1px solid #cccccc;
	height: 24px;
	line-height: 24px;
	min-width: 90px;
	width: 100%;

	&.in_lead {
		min-width: 160px;
	}

	&.act-btn {
		text-transform: none;
	}
}

/* Leads */

.page-main{
	padding: 0px 20px 10px 20px;
}

.selectStyle{
	// border:1px solid #3B82F6 !important;
	max-height: 40px;
	border-radius:6px;	
}

.colorred{
	color:#EF4444 !important;
}

.deal-list-item{
	border:1px solid #caddfc !important;
}

.cardbg{
	background-color:#DCFCE7 !important;
	border:1px solid #a1bfac !important;
}

.with100, .staff_wrapper{
	width:100% !important;
}


.staff_wrapper, .list_paper, .staff_paper{
	max-width:100% !important;
	margin:auto;
}
.sec-button{
	padding:10px 40px !important;
	border:2px solid #3B82F6 !important;
	color:#3B82F6 !important;
	background-color:#fff !important;
}
.marauto{
	margin: auto !important;
}
.padtwenty{
	margin:20px !important;
}

/* Deals */

.deal-list-item:hover{
	background-color:#f0f5fc !important;
}
.manage-tabs {
	border-bottom: 1px solid #e8e8e8;
	.indicator {
		background-color: #2979ff;
	}
}
.manage-tab {
	text-transform: capitalize !important;
	font-size: 14px !important;
	min-width: 72px;
	font-weight: 500;
	margin-right: 4px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	&:hover {
		color: #2196f3;
		opacity: 1;
	}
	.selected {
		color: #2979ff;
		font-weight: 500;
	}
	&:focus {
		color: #2979ff;
	}
}
.topbar-main {
	box-shadow: none !important;
	background-color: #000000;
	margin-left: 100px;
}
.main-title {
	color: #ffffff;
}
.hamburger {
	padding-left: 0px;
}
.user-identity {
	display: flex;
	align-items: center;
	.staff-name {
		color: #ffffff;
		margin-right: 15px;
	}
}
.client-name {
	display: flex;
	color: #fff;
	background-color: rgba(255, 255, 255, 0.36);
	padding: 5px 2px 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	user-select: none;
}


.e-template-list {

    .email-temp-card {
        width: 150px;
        margin-bottom: 15px;
    }
}



.email-container {
    width: 600px;
    border: 1px dashed #e0e0e0;

    .email-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        background: #f0f0f0;
        border-bottom: 1px solid #adacac;
        position: relative;

        .email-logo-section {
            position: relative;

            .logo-in-email {
                height: 60px;
            }

            .logo-upload-btn {
                position: absolute;

                .logo-upload-input {
                    display: none;
                }
            }
        }

        .email-header-text {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 700;
        }
    }

    .email-body {
        position: relative;
        height: 100px;
        padding: 30px;

        .email-body-cover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            backdrop-filter: blur(var(--glass-blur, 2px));
            background-color: transparent;
        }
    }

    .email-footer-first {
        background: #f0f0f0;
        height: 83px;
        border-bottom: 1px solid #adacac;
        border-top: 1px solid #adacac;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        position: relative;

        .first-left {
            flex-grow: 1;
            flex: 1 1 0px;
            padding: 15px;
        }

        .first-right {
            flex-grow: 1;
            flex: 1 1 0px;
            padding: 15px;
            border-left: 1px solid #adacac;
        }
    }

    .email-footer-second {
        background: #f0f0f0;
        height: 147px;
        text-align: center;
        padding: 30px;
        position: relative;

        .social-links {
            display: flex;
            justify-content: center;
            height: 30px;

            .social-logo-holder {

                .social-logo {
                    height: 30px;
                    margin-left: 10px;
                }

                .social-tools {
                    
                    .social-remove-btn {
                        padding: 0;
    
                        .social-remove {
                            height: 15px;
                            width: 15px;
                            margin-bottom: 15px;
                        }
                    }
                }

            }
        }
    }

    .footer-texts-bold {
        font-size: 14px;
        font-weight: 600;
    }

    .footer-text6 {
        margin-top: 20px;
    }

    .footer-text7 {
        margin-top: 30px;
    }
}

.text-inline-input {
    border: none;
    background: none;
    padding: 0;

    &:focus {
        outline: none;
    }
}

.email-header-input {
    text-align: right;
}

.email-text-width {
    width: 100%;
}

.email-center-text-input {
    text-align: center;
}

.social-logo-upload-input {
    display: none;
}


.social-paper {
    padding: 15px;

    .social-popper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.email-body-format-warpper {

    .email-format-body {
        font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
        min-height: 150px;
        width: 100%;
        resize: none;
        border: none;
    
        &:focus {
            outline: none;
        }
    }
}

.email-blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(var(--glass-blur, 2px));
    background-color: transparent;
}
.email-tabs {
    border-bottom: 1px solid #e8e8e8;
    .email-tab {
        text-transform: none;
        min-width: 72px;
        font-weight: 500;
        margin-right: 4px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        &:hover {
            color: #2196f3;
            opacity: 1;
        }
        &:focus {
            color: #2979ff;
        }
        .selected {
            color: #2979ff;
            font-weight: 500;
        }
    }
}

.staff_title_bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.wrapper {
    display: flex;

    .staff_section {
      display: flex;
      flex-direction: column;
    }

    .staff_section_two {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }
  }

  .staff_input {
    margin-top: 15px !important;
  }
  
  .button-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  
      .staff_buttons {
          margin-top: 10px;
          min-width: 190px;

          &.staff_delete {
            margin-top: 15px;
            grid-column: 1 / span 2;
            color: #FF0000;
            border-color: #FF0000;
          }
      }
  }
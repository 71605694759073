
.fnt16{
	font-size:16px !important;
}

.fnt19{
	font-size:19px !important;
}

.contact__name {
    border-radius: 5px;
    color: #000;
    padding: 3px 10px;
}

.leadcardbg{
	background-color: $blue-dim !important;
	border:1px solid #2196f3 !important;
	padding:10px;
	border-radius:6px;

    &.deal-note {
        margin-bottom: 10px;
    }
}

.no-info{
	@extend .error-container;
	margin-bottom:20px !important;
}

.da-error {
    @extend .error-container;
}

.lead-main-data{
	background-color: $blue-dim;
    border: 0 !important;
    margin-bottom: 1px;
    border-radius: 6px 6px 6px 0;
}

.dvdr-color{
	background-color:#919fb2 !important;
}


.leads-tools-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    .lead-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .lh-left-side {
            display: flex;
            justify-content: left;
            align-items: center;

            .rf-container {
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                margin-right: 10px;
                height: 38px;

                .remove-filter {
                    padding: 5px;
    
                    .rf-img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}


.form-listing , .lead-type ,.status-listing,.priority-listing, .lead-active-status
{
    margin-right: 10px;
}
.customers-page{
    // background: #ebebeb;
    padding: 10px;
}
.add-note {
    margin-top: 10px;
    width: 100%;
    min-width: 375px;
    height: 90px;
    border-radius: 5px;
    padding: 10px;
    outline-color: $primary-color;
    resize: none;
}

.add-note-button {
    /*text-align: right;*/
	text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

div.file-attached {
    margin: 3px 3px 0 0;
}

.view-note{
    margin: 0px;
    width: 478px;
    height: 142px;
}
.MuiFilledInput-input {
    padding: 10px !important;
}
.MUIDataTableHeadCell-data-118 {
 
    text-transform: uppercase;
}
.MUIDataTableHeadCell-fixedHeader-115 {
     background-color: #F0F0F0 !important;
     text-transform: uppercase;
}
.MuiButton-root{
    text-transform: capitalize !important; 
}
.lead-head {
    font-size: 13px;
    color: $primary-color;
}
.lead-data {
    font-size: 15px;
}

.notes-list-container {
    margin-top: 25px;
}

.note-item {
    border: 1px solid #c4cbcf;
    margin-bottom: 30px;
    font-size: 15px;
	border:0px;
    
    .note-text {
        text-align: justify;
    }
    
    .note-bottom-panel {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .notes-owner-sec {
            display: flex;
            margin-left: 15px;

            .notes-owner-img {
                width: 13px;
                height: 13px;
                margin-right: 3px;
            }
        }
    }
}

span.read-more-btn {
    color: #2196f3;
    cursor: pointer;
}


h6.MuiTypography-h6 {
    font-size: 18px;
    line-height: 20px;
}

em{
    padding-right:25px !important;
}
.MuiTableCell-root{
    padding:10px !important;
}

.leads_search_input {
    margin-left: 15px;
    flex: 1;
}

.add_to_contact_sections {
    display: flex;

    .contact-details-sec {
        width: 100%;
    }

    .dialog-section {
        min-width: 250px;
        padding: 8px 24px;
    }

    .lead_for_contact {
        .lead-head {
            color: $primary-color;
        }

        .lead-data {
            font-size: 15px;
        }
    }

    .lead-contact_table {
        margin-top: 10px;

        .lead_contact {
            .contact_key {
                color: $primary-color;
                font-size: 16px;
            }
        }
    }

    .account_list_and_add {
        display: flex;
        flex-direction: column;
    
        .account_wrapper {
            border: 1px solid #e0e0e0;
        }
    
        .cancelAddWrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 10px;
        }
    
        .addNewAccount {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    .key_style {
        color: $primary-color;
        font-size: 16px;
    }
    

    .email_add_section {
        display: grid;
        margin-top: 20px;
        
        .email_strip {
            background-color: #e7e7e7;
            padding: 9px 10px;
            margin-top: 15px;
            border-radius: 6px;

            &.default_one {
                border-bottom: 3px solid $primary-color;
            }
        }

        .email_add_wrapper {
            margin-top: 15px;
        }
    }

    .phone_section {
        margin-top: 20px;

        .phone_add_wrapper {
            margin-top: 15px;
        }

        .phone_strip {
            background-color: #e7e7e7;
            padding: 9px 10px;
            margin-top: 15px;
            border-radius: 6px;

            &.default_one {
                border-bottom: 3px solid $primary-color;
            }
        }
    }
}

.atc_button_bar {
    display: flex;
    padding: 16px 24px !important;

    .lead-contact-warning {
        @extend .warning-container;
        display: flex;
        margin-left: 15px;
        align-items: center;
    }
}

.contact_value {
    margin-top: 15px !important;
    width: 250px;

    &.deal_c_value {
        width: 100%;
    }
}

.contact-add-edit-button {
    min-width: 40px !important;
}

.lead-form-container {
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #a1a1a1;
    border-radius: 6px;
    min-width: 400px;

    .form-frame {
        border: none !important;
        min-width: 400px;
    }
}

.account_name_in_contact {
    background-color: #ededed;
    padding: 0 6px;
    border-radius: 6px;
}

.fu-email-title {
    display: flex;
    justify-content: left;
    align-items: center;

    .fu-email-t-text {
        margin-left: 5px;
        margin-right: 10px;
        font-size: 20px;
    }

    .fu-email-client {
        border: 1px solid $border-color;
        padding: 5px;
        border-radius: 5px;
        font-size: 11px;
        color: #546e7a;
    }
}

.tl-title {
    display: flex;
    justify-content: left;
    align-items: center;

    .timeline-t-text {
        margin-left: 5px;
        margin-right: 10px;
        font-size: 20px;
    }

    .timeline-client {
        border: 1px solid $border-color;
        padding: 0px 5px;
        border-radius: 5px;
    }
}

div.tl-arr {
    margin: 0 25px;
    padding: 5px 0;
    border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.12);
}


.fu-email-tools {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid $grey-back;
    border-top: 1px solid $grey-back;

    .draft-btn {
        border: 1px solid $border-color;
        border-radius: 5px;
        padding: 0 10px;
        max-height: 40px;

        .draft-txt {
            margin-left: 5px;
            font-size: 14px;
            color: #263238;

            &.disabled {
                color: $border-color;
            }
        }
    }

    .fu-right-tool {
        
        .fu-dropdown {
            margin-left: 10px;
            width: 170px;
            max-height: 40px;

            &.large {
                width: 220px;
            }
        }
    }
}

.e_body_bar {
    display: flex;
    justify-content: space-between;

    .temp_var_holder {
        display: flex;
        padding-left: 10px;
        border-left: 1px solid #e0e0e0;

        .chips-container {
            max-width: 200px;
            overflow: hidden;

            .chips-rail {
                @extend .hide-scrollbars;
                overflow-x: scroll;
                display: flex;
            }
        }

        .var_chip {
            margin-right: 5px;
        }
    }
}

.temp_var_container {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.temp_var_item {
    display: flex;
    justify-content: space-between;

    .var_data {
        display: flex;
        min-width: 150px;
    }

    .var_tools {}
}

.variable_input_container {
    padding: 0 15px 15px;
}

.variable_input_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .var_save_wrapper {
        display: flex;
        justify-content: space-evenly;
        padding-top: 10px;

        &.update {
            justify-content: none;
            flex-direction: column;
        }

        .var_save_btns {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 10px;
            // border-left: 1px solid #c7c7c7;
        }
    }
}

.copy_variable_btn:hover {
    color: #0bc717;
}

.fu-email-to {
    display: flex;
    justify-content: space-between;

    .fu-email-cc-wrapper {
        display: flex;

        & > h5 {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.bcc-container {
    margin-top: 10px;
}

.fu-email-sub-wrap {
    margin-top: 10px;

    .fu-email-sub {
        width: 100%;
    }
}

.fu-eml-body {
    margin-bottom: 15px;
    margin-top: 10px;

    .fu-eml-bd-paper {
        padding: 15px;

        .fu-email-ta {
            width: 100%;
            min-width: 512px;
            min-height: 180px;
            border-radius: 5px;
            padding: 0;
            outline-color: $primary-color;
            border: none;
            resize: none;
    
            &:focus {
                outline: none;
            }
        }
    }
}

.mail-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .template-actions {
        display: flex;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        align-items: center;
        margin-bottom: 5px;
    }
}

button.more-btn-selector {
    padding: 0;
    min-width: 20px;
}

button.split-button-menu {
    padding: 0 5px;
    line-height: .75;

    & > span {
        margin-left: 5px;
        color: rgb(15, 9, 9);
    }
}

.email-chips-paper {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-top: 0;

    .email-chips-wrapper {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        list-style: none;
        padding: 5px;
        margin: 0;
    }

    .email-chip {
        margin: 0 5px 5px 0;
    }

    .email-chips-reset {
        button {
            padding: 6px;
        }
    }
}

.temp-save-btn-grp {
    display: grid;

    & > button {
        margin-top: 10px
    }
}

.action-success {
    @extend .auth_paper;
    display: flex;
    flex-direction: column;
    align-items: center;

    .success-color {
        color: $success;
    }
}

.lead-list-tbl {
    & > div:first-child > div:nth-child(2) {
        display: flex;
        justify-content: right;
    }
}

div.emails-input-field {
    padding: 15px;
}

.l_id_hldr {
    display: flex;
    flex-direction: column;

    .test_flag {
        padding: 1px 6px;
        background: #fff088;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #edd00a;
        font-size: 10px;
        line-height: 11px;
        align-self: flex-start;
        margin: 3px;
        color: #000;
        margin-left: 0;
    }

    .staff_flag {
        padding: 1px 6px;
        background: #31e868;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #0bc717;
        font-size: 10px;
        line-height: 11px;
        align-self: flex-start;
        margin: 3px;
        color: #fff;
        margin-left: 0;
    }

    .new_lead_flag {
        padding: 1px 6px;
        background: #f55b5b;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #fd3434;
        font-size: 10px;
        line-height: 11px;
        align-self: flex-start;
        margin: 3px;
        color: #fff;
        margin-left: 0;
    }

    .form_name_flag {
        padding: 1px 6px;
        background: #5e79e5;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #6334fd;
        font-size: 10px;
        line-height: 11px;
        align-self: flex-start;
        margin: 3px;
        color: #fff;
        margin-left: 0;
    }

    .sched_flag {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 11px;
        align-self: flex-start;
        margin: 3px;
        margin-left: 0;
    }
}

div.staff-avatar {
    background-color: rgb(253, 95, 122);
    height: 30px;
    width: 30px;
    margin-right: 10px;

    &.active {
        background-color: #31e868;
    }
}

.lead_assigned_msg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .f-l-count {
        color: #FFFFFF;
        background-color: #e53935;
        height: 20px;
        display: flex;
        padding: 0 6px;
        z-index: 1;
        font-size: 0.75rem;
        min-width: 20px;
        box-sizing: border-box;
        transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1;
        align-content: center;
        border-radius: 10px;
        flex-direction: row;
        justify-content: center;
    }
}

div.creation_date {
    width: 118px;
}

.leadBotBar {
    padding: 0 15px;
}

.leadTL {
    padding: 0 15px;
    padding-bottom: 15px;
}

div.p_switcher {
    white-space: nowrap;
    display: flex;
    margin-left: 10px;
    height: 36px;
    // border: 1px solid #c4c4c4;
    border-left: 1px solid #c4c4c4;
    width: 177px;

    label {
        margin-left: 0;
    }
}

.advanced_filter_btn {
    white-space: nowrap;
    margin-left: 15px;
}

.advanced_filters {
    margin-bottom: 20px;
}

div.dynamic_filter {
    margin-left: 15px;

    &:first-child {
        margin-left: 0;
    }
}

button.dfilter_btns {
    margin-top: 10px;
    border: 1px solid #c4c4c4;
    color: #212136;
}

button.active_dfilter_btns {
    margin-top: 10px;
}

button.dfilter_btn_common {
    border: 1px solid #c4c4c4;
    color: #212136;

}

div.custom_date_filter {
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 6px;
    text-align: center;

    &.filter-applied {
        border-color: $primary-color;
    }
}

div.toolbar-btn {
    display: flex;
    align-items: center;

    button {
        padding: 0;
    }
}

.mapping-field-head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;

    p {
        font-weight: bold;
    }
}

.mapping-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    margin-bottom: 10px;
}

.disable-field-map-grid {
    pointer-events: none;
    opacity: .4;
}

form.lead-upload-form {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.form-name {
    color: #4d5867;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    padding: 0 5px;
}

.required-field {
    color: #e53935;
}

.import-info {
    display: flex;
    align-items: top;
    padding-top: 5px;

    svg {
        margin-right: 3px;
    }
}

.click_link {
    margin-top: 15px;
    text-decoration: underline;
}

.template_error_msg {
    position: absolute;
}

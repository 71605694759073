.deals_container {
    .deals-view-container {
        position: relative;
        height: calc(100vh - 215px);

        .deals-view-funnel {
            display: flex;
            overflow-x: scroll;
            align-items: stretch;
            position: absolute;
            width: 100%;
            height: 100%;
            margin-left: 5px;
            padding-right: 10px;

            .empty-stages-message {
                @extend .empty-message;
                width: fit-content;
                margin: auto !important;
                margin-top: 100px !important;
            }
            
            .deals-view-funnel-item {
                margin: 15px;
                min-width: 300px;

                .stage-name-head {
                    padding: 15px;
                }

                .deals-head-divider {
                    margin-bottom: 0 !important;
                }

                .deals-list {
                    padding: 15px;
                    padding-top: 0;
                    overflow-y: scroll;
                    height: calc(100% - 65px) ;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .deal-list-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: top;
                        padding: 15px;
                        margin-top: 15px;
                        border-radius: 4px;
                        cursor: pointer;
    
                        &:hover {
                            background-color: #e1fce7;
                        }
    
                        .deal-title {
                            color: $primary-color;
                        }
    
                        .deal-content {
                            display: flex;
                            justify-content: left;
                            align-items: center;
                            padding-top: 10px;
    
                            .deal-value {
                                margin-right: 10px;
                                margin-top: 0 !important;
                            }
    
                            .deal-account {
                                margin-left: 10px;
                            }
                        }
                    }

                    .no-deals-message {
                        text-align: center;
                        margin: 130px auto;
                    }
                }

            }
        }
    }

    .deal-toolbar {
        padding: 0 20px;
        margin-bottom: 5px;
        margin-top: 17px;
    }
}

.deal-name-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deal-summary-name {
    color: $primary-color !important;
    margin: 15px 0 !important;
}

.related-contacts {
    display: flex;
    flex-direction: column;

    .related-contacts-text {
        white-space: nowrap;
    }

    .related-contacts-group {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        padding: 5px;
        border-radius: 10px;
        background-color: rgba(237, 238, 238, 0.438);
        width: 100%;

        & > * {
            margin: 5px;
        }

        .contact-avatar {
            background-color: rgb(253, 95, 122);
        }
    }
}

.contact-details-popover {
    padding: 15px;
    min-width: 150px;

    .contact__head {
        font-weight: bold;
    }

    .contact__value {
        padding: 3px 10px;
        border-radius: 5px;
        background-color: #e7e7e7;
        margin-bottom: 5px;
    }
}

.summary-divider {
    margin: 0 15px !important;
}

.deal-summary-basic {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    &.deal-stage-show {
        margin-top: 10px
    }

    .currency_sbl {
        color: $success;
    }
}


.deals-section {
    display: flex;
    flex-direction: column;

    .addNewDeal {
        margin-top: 10px;
    }
}

.create-deal-form {

    .create-deal-head {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .new-deal-name {
        margin-top: 15px;
    }

    .dealOptionsWrapper {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
    }

    .add-new-deal {
        text-align: center;
    }
}

.rc-tool {
    display: flex;
    justify-content: space-between;

    .related-contacts-edit-wrapper {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        padding: 5px;
        padding-right: 10px;
        border-radius: 10px;
        background-color: rgba(237, 238, 238, 0.438);
        width: 100%;
        // max-width: 355px;

        .related-contact-chips {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    .related_contacts_btn_wrapper {
        display: flex;
        align-items: flex-end;
    }
}


.contacts-list-paper {
    padding: 15px;
    max-height: 300px;
    overflow-y: scroll;
    margin-top: 15px;
}

.add-note-btn-wrapper {
    display: flex;
    justify-content: right;
}

.window-active-icon {
    color: $success;
}

.dn-nav-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dn-nav-wrapper {
        display: flex;
        justify-content: right;
    }
}

.tl-container {
    position: relative;

    .tl-line {
        border-left: 3px solid #4cffa8;
        position: absolute;
        height: 100%;
        top: 0;
        left: 5.5px;
        z-index: 100;
    }

    .tl-content-holder {

        .tl-dot {
            display: flex;
            margin-bottom: 15px;
            z-index: 200;
    
            &:before {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                margin-right: 15px;
                border-radius: 15px;
                background-color: #2abe77;
                z-index: 200;
                left: 0;
            }

            .tl-content {
                padding-top: 2px;
                margin-left: 20px;

                .tl-datetime {
                    font-size: 12px;
                }

                .tl-time-diff-wrapper {
                    margin-bottom: 5px;

                    .tl-time-diff {
                        font-size: 10px;
                        background-color: #f2f2f2;
                        border: 1px solid #eaf1fb;
                        padding: 0 5px;
                        border-radius: 5px;
                    }
                }
    
                .tl-text {
                    @extend .success-container;
                }

                .tl-email-text {
                    background-color: #eaf1fb;
                    padding: 5px;
                    margin-top: 10px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    .tl-email-details {
                        display: flex;
                        align-items: center;

                        .tl-email-sub {
                            margin-left: 5px;
                        }
                    }

                    .email-arrow-btn {
                        padding: 0;
                    }
                }

                .tl-e-details {
                    background-color: #eaf1fb;
                    padding: 10px;
                    margin-top: 10px;
                    border-radius: 6px;
                    overflow-wrap: anywhere;

                    .tl_fl_label {
                        border-bottom: 1px solid #cdd8e7;
                        margin-bottom: 3px;
                        font-weight: 600;
                    }

                    .tl_fl_value {
                        max-width: 350px;
                        overflow-wrap: anywhere;
                    }

                    .tl-e-heads {
                        font-size: 12px !important;
                        margin-top: 10px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }

                    .tl-e-values {
                        padding: 5px;
                        border-radius: 6px;
                        background-color: #d9e4ea;
                        margin-top: 3px;
                        overflow-wrap: anywhere;
                    }
                }

                .notes-owner-sec {
                    display: flex;
                    margin-top: 10px;
        
                    .notes-owner-img {
                        width: 13px;
                        height: 13px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }
}
.address-form {
    text-align: left;

    .address-input {
        width: 100%;
        margin-bottom: 15px;
        max-height: 40px;
    }

    .cf_label_bar {
        display: flex;
        justify-content: space-between;

        .remove__icon {
            padding: 0;
        }
    }

    .custom__fields {
        margin-bottom: 15px;
        padding: 15px;
        background-color: $blue-light;
        border-radius: 15px;
    }

    .address-form-divider {
        margin: 15px 0;
    }

    .address-actions {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;

        .action-btns {
            width: 100%;
        }
    }
}

.form_heading {
    margin-top: 30px !important;
}

h5.form_heading {
    font-weight: 500;
    font-size: 24px;
}

.text-field{
    margin: 10px !important;
    width:100% !important;
}
 .select-field{
    margin: 10px !important;
    width:100% !important;
}
.addFieldButton{
    margin: 10px !important;
}

h6.sub-head {
    margin-bottom: 20px;
    font-weight: 500;
}
.addFormFeild{
    margin:20px 0px 20px 0px;

    .editNAddWrapper {
        display: flex;
        align-self: flex-start;
        padding-top: 12px;
        padding-left: 9px;
    }
}
.editor{
    height: 600px;
    width:100%;
    margin-top:25px;
}
iframe{
    border:1px solid #263238!important;
}
.logo img{
    display: none !important;
}
.top .top-left .logo img {
    display: none;
}
.Addform{
    padding: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
iframe{
    border: none;
}
iframe  img{
    display: none !important;
}
.mandatory_fields {
    display: flex;
    pointer-events: none;
    opacity: .7;
}




.lead-details-heading{
    padding: 10px 0px 10px 0px !important;
    display: flex;
    justify-content: left;
    align-items: flex-end;


    .ld-snip {
        &::before {
            content: "  .   ";
            margin-left: 5px;
            font-weight: bolder;
        }
    }
}

.lead-main-data {
    border: 1px solid #e0e0e0;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;

    .lead-main-wrapper {
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;

        .lead-head {
            color: $primary-color;
        }

        .lead-data {
            font-size: 15px;
        }
    }
}

.lead-list {
    border-spacing: 0px !important;
    width: 100%;

    .lead-details {

        .lf-value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 10px;

            .lv-input {
                width: 100%;
                word-wrap: break-word;
                max-height: 40px;
                
            }

            .saveCloseBtns {
                display: flex;
                padding: 12px 0 12px 12px;
                

                & > button {
                    padding: 5px;
                }
            }
        }

        td {
            border-top: 3px solid #fff;
        }

        td:first-child {
            background-color:$blue-dim;
            vertical-align: middle;
        }

        .lead-key {
            color: $primary-color;
            padding: 10px;
            max-width: 250px;
            font-size: 16px;
        }

        .lead-value {
            min-width: 180px;
            word-wrap: break-word;
            font-size: 14px;
        }
    }

    tr:last-child td:first-child {
        border-radius: 0 0 6px 6px;
    }
}

.sidebar-drawer {
    width: 240px;
    @media (min-width: 1199px) {
        margin-top: 64px;
        height: calc(100% - 64px);
    }
}
.sidebar-main {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 220px;
    font-size: 14px;
}
.divider {
    margin: 2px 0px
}
.nav {
    margin-bottom: 2px;
}
.foot-style {
    text-align: center;
    position: relative;
    margin-top: auto;
}

*{
    box-sizing: border-box;
}
html, body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    line-height: 1.5;
    scroll-behavior: smooth;
}
header.doc-header {
    background: #2196f3;
    color: #fff;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1500;
    height: 64px;
    justify-content: space-between;

    .brand-holder {
        display: flex;
        position: relative;
        align-items: center;
        padding-left: 24px;

        .doc-hb-menu-btn {
            padding-left: 0;
        }
    }

    h1 {
        margin: 0;
        padding-left: 40px;
    }

    .back-th {
        margin-right: 50px;

        p {
            color: #ffffff;
            text-decoration: underline;
        }
    }

    @media (min-width: 640px) {
        width: 100%;
        margin-left: 0;
    }
}
.docs {
    display: flex;
    h1 {
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
        margin-bottom: 60px;
    }
    .sidebar {
        position: sticky;
        align-self: flex-start;
        top: 0;
    }
    .docs-content {
        width: auto;
        margin-left: 20px;
        @media (min-width:1199px) {
            margin-left: 260px;
        }
        margin-right: 20px;
        flex-grow: 1;
    }
    .docs-content-block {
        padding: 60px 0;
    }
}
.sidebar {
    width: 100%;
    padding: 15px;
    font-size: 14px;
    > ul {
        > li {
            > a{
                font-weight: bold;
                border-bottom: 1px solid #eee;
            }
        }
    }
    ul {
        padding: 0;
        counter-reset: section;
        margin-bottom: 20px;
        ul {
            // max-height: 0;
            // overflow: hidden;
            margin-top: 10px;
            transition: max-height ease .3s;
            counter-reset: subsection;
            li {
                counter-increment: subsection;
            }
            a {
                padding-left: 20px;
                &:after {
                    content: counter(section) "." counter(subsection);
                    margin-right: 10px;
                }
            }
        }
        &.open {
            max-height: 1000px;
        }
    }
    
    .section-active {
        ul {
            max-height: 1000px;
        }
    }
    li {
        list-style: none;
        margin: 0;
        counter-increment: section;
        &.active {
            > a, h3 a {
                background: #2196f3;
                color: #fff;
                width: 100%;
            }
        }
    }
    a {
        border-radius: 5px;
        display: flex;
        padding: 10px 0px 10px 10px;
        transition: all ease .2s;
        &:after {
            // content: counter(section);
            margin-right: 10px;
            margin-left: auto;
        }
    }
    h3 {
        margin: 0;
    }
    p {
        color: #646464;
    }
}

.doc-content {
    counter-reset: section;
    // max-width: 1000px;
    margin: auto;
    p{
        margin-bottom: 40px;
    }
    h2, h3 {
        // scroll-margin-top: 70px;
    }
    h2 {
        counter-increment: section;
        padding-top: 10px;
        // &:before {
        //     content: counter(section);
        //     margin-right: 10px;
        // }
    }
    h3 {
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
        margin: 0 0 30px;
        padding-top: 15px;
    }
}
.content-section {
    counter-reset: subsection;
    // margin-bottom: 100vh;
    padding: 20px 20px;
    
    h3 {
        counter-increment: subsection;
        // &:before {
        //     content: counter(section) "." counter(subsection);
        //     margin-right: 10px;
        // }
    }

    .each-doc-section {
        color: #263238;
        font-size: 14px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.05px;

        a {
            text-decoration: underline;
            color: #2196f3;
        }

        ol ul {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        li {
            margin-bottom: 10px;
        }
    }
}

.clog_link {
    background-color: #f0f8ff;
    color: #2196f3;
}
.docs-drawer {
    width: 240px;
    flex-shrink: 0;
}
.content {
    flex-grow: 1;
    padding: 3px;
    margin-left: 0px;
    @media (min-width: 1199px) {
        margin-left: 240px;
    }
}
.content-shift {
    margin-right: 20px;
    @media (min-width:0px) and (max-width:900px) {
        margin-left: 20px;
    }
    @media (min-width:900px) and (max-width:1200px) {
        margin-left: 0px;
    }
    @media (min-width:1201px) {
        margin-left: 20px;
    }
}
.docs-drawer-paper {
    width: 240px;
    padding-top: 60px;
}
.vip-tiers {
  padding: 30px;
  margin: 0;
  h6 {
    padding: 10px 0 40px 0;
    font-size: 18px;
  }
  h4 {
    font-weight: 400;
    padding: 0 0 5px 0;
    font-size: 16px;
  }
  div#tier-settings {
    .MuiPaper-root {
      padding: 15px 20px;
      margin: 0 0 60px 0;
    }
    span.MuiTypography-caption {
      padding: 0 0 0 30px;
    }
  }
  .MuiFormControlLabel-label {
    font-size: 15px;
  }
  //   .MuiSvgIcon-root {
  //     font-size: 1rem;
  //   }
  //   legend#form-head {
  //     font-size: 20px;
  //   }
  label.MuiInputLabel-outlined {
    font-size: 15px;
  }
  p#life-time-period {
    font-size: 15px;
  }
  //   div.MuiGrid-item {
  //     input.MuiOutlinedInput-input {
  //       padding: 0 0 10px 0;
  //     }
  //   }
  fieldset {
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }
    p#life-time-period {
      padding: 0 0 10px 0;
    }
  }
  div#button-grid {
    text-align: right;
  }
  div#tier-type {
    p {
      display: inline;
      font-weight: bold;
    }
    span.MuiTypography-caption {
      padding: 0 0 0 5px;
    }
  }
  button#deletebtn {
    background-color: #f4511e;
  }
  div.MuiAccordionDetails-root,
  div.MuiAccordionSummary-content {
    display: inline;
  }
  select.MuiSelect-outlined {
    padding: 10px;
  }
}

$profileWidth: 30%;
$textCenter: center;
$iconColor: #37383d;
$borderColor: 1px solid #dfe3e8;
$profileDivPadding: 20px 15px 20px 15px;
$profileContentPadding: 5px 0 5px 0;
$bold: bold;
$h6NewSize: 12px;
$h6NewPadding: 15px 5px 0 5px;
$displayInline: inline;
$iconSize: 15px;
$linkPadding: 0 0 5px 15px;
$iconPositon: translateY(3px);
$profilebtnWidth: 100%;
$btnMarginBottom: 5px;
$btnMarginTop: 25px;

div.details-page {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // margin: 0;
  padding: 30px;
  // div.customer-status {
  // width: 65%;
  thead:first-child {
    background-color: $iconColor;
  }
  select {
    appearance: none;
  }
  thead:first-child {
    th {
      color: #ffff;
    }
  }
  thead:last-child {
    background-color: #e0e0e0;
  }
  div.MuiTableContainer-root {
    margin: 0 0 20px 0;
  }
}
div.captions {
  padding: $profileContentPadding;
  button {
    width: $profilebtnWidth;
    margin-bottom: $btnMarginBottom;
  }
  button:first-of-type {
    margin-top: $btnMarginTop;
  }
  h6 {
    font-weight: $bold;
    font-size: $h6NewSize;
    padding: $h6NewPadding;
    word-spacing: 3px;
  }
  span {
    padding: 0 0 0 15px;
    display: block;
  }
}

#help-icon {
  font-size: $iconSize;
  transform: $iconPositon;
  color: $iconColor;
}
h6#customer-tags {
  display: $displayInline;
}
a#referral-link {
  padding: $linkPadding;
  font-size: 12px;
}
.profile {
  text-align: $textCenter;
  border-bottom: $borderColor;
  padding: $profileDivPadding;
}
#profile-icon {
  color: $iconColor;
  font-size: 40px;
}
div.details {
  padding: $profileDivPadding;
}
//}
div.MuiGrid-container {
  border: none;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
}
div#grid {
  box-shadow: none;
  padding: 0;
}

.contact_accounts {
    .accounts__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 20px;
        gap: 20px;

        .account__card {
            display: flex;
            flex-direction: column;

            .action__divider {
                margin-top: auto;
            }
        }
    }
}
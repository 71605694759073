.cl_container {
    padding: 20px;

    .release_holder {
        max-width: 800px;

        .release-details {
            padding: 15px;
            border: 1px solid #eeeeee;
            border-radius: 6px;
            margin: 15px 0;
        }
    }
}
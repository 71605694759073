@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url("https://fonts.gstatic.com");
.form-data{
    border: 1px solid #efefef;
    padding: 30px;
    margin: 10px;
    overflow: scroll;
}
iframe{
    border:1px solid #263238;
}

.editor{
    height: 600px;
    width:100%;
    margin-top:25px;
}
.logo img{
    display: none !important;
}
.click-here {
    font-size: 14px;
    margin-top: 10px;
    padding: 5px;
    background: lemonchiffon;    
    text-align: center;

}
.Addform{
    padding: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
iframe{
    border: none;
}

.form_fields {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
    padding: 10px;
    padding-right: 0px;
    margin-bottom: 15px;
    background-color: #fff;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    &.common_fields {
        border-top: 2px solid rgb(169, 169, 169);
    }

    .field_editor {
        width: 100%;

        .field_attr_wrapper {
            display: flex;
        }
    }
}

.man_field_clr {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    background-color: rgb(169, 169, 169);
}

.form_add_del_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-left: 1px solid #efeded;
    margin-left: 10px;
}

.form_sec_head {
    padding-bottom: 15px;
    border-bottom: 1px solid #efeded;
}
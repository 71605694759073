.item {
    display: flex;
}
.button {
    padding: 3.5px 3.5px;
    color: #37474f;
    justify-content: flex-start;
    text-transform: none;
    letter-spacing: 0;
    width: 100%;
    font-weight: 500 ;
}
.icon {
    padding: 3.5px 3.5px;
    color: #546e7a;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    .active {
        color: #2196f3
    }
}
.active {
    padding: 3.5px 0px 3.5px 4px;
    color: #2196f3;
    font-weight: 500;
    justify-content: flex-start;
    text-transform: none;
    letter-spacing: 0;
    width: 100%;
    display: flex;
    align-items: center;
    .icon {
        color: #2196f3;
    }
}
.active-icon {
    color: #2196f3;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
}
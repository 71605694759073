div.loyalty-performance {
  h2 {
    margin: 0;
    padding: 0 0 30px 0;
  }
  margin: 0;
  padding: 30px;
  h6 {
    padding: 10px 0 0 0;
  }

  div#filters {
    margin: 0 0 30px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
    button {
      font-size: 12px;
      padding: 2px 5px;
      margin: 0 15px 0 0;

      p {
        font-weight: bold;
      }
    }
    // button#runbtn {
    //   float: right;
    // }
    h6 {
      font-size: 12px;
      display: inline;
      padding: 0 20px 0 8px;
    }
    p {
      display: inline;
    }
  }
  h4#overview {
    text-align: center;
    margin: 0;
    padding: 20px 0 10px 0;
  }
  h3 {
    font-size: 20px;
    text-align: center;
  }

  div.MuiGrid-container {
    border: none;
    box-shadow: none;
    margin: 0;
    max-width: 100%;
    span {
      font-size: 18px;
      padding: 10px 0 0 0;
      font-weight: bold;
      // color: #ffff;
    }
    .recharts-tooltip-wrapper {
      opacity: 0.8;
      ul.recharts-tooltip-item-list {
        font-size: 12px;
      }
      .recharts-tooltip-label {
        font-size: 12px;
      }
    }
    // #line-graph {
    //   padding: 0 0 10px 0;
    // }
  }

  //   div#total-loyalty {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //   }
}

.main {
    padding-top: 56px;
    height: 100%;
    @media (min-width: 600px) {
        padding-top: 64;
    }
}
.shift-content {
    padding-left: 0px;
}
.content {
    height: 100%;
}

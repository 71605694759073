$searchbarPadding: 8px 60px 8px 15px;
$searchbarFontSize: 12px;
$searchbtnPadding: 4px 10px;
$searchbtnMargin: 0 0 0 10px;
$searchbtnFontSize: 12px;
$floatRight: right;
$alignCenter: center;
$searchListMargin: 0 0 15px 0;
$customerPagePadding: 30px;
$customersPadding: 0 0 15px 0;
$tableContainerMargin: 0 0 30px 0;
$tableRowColor: #f5f5f5;
$tableContentHover: #3fb1c5;
$tdContent: #263238;
$black: #263238;
$textDec: none;
$displayFlex: flex;
$flexWrap: wrap;
$cursorPointer: pointer;

div.customer-page {
  padding: $customerPagePadding;
  margin: 0;
  tr:hover {
    background-color: $tableRowColor;
  }
  td {
    cursor: $cursorPointer;
    color: $tdContent;
  }
  .MuiTableContainer-root {
    margin: $tableContainerMargin;
    text-align: $alignCenter;
  }
  h6 {
    padding-top: 10px;
  }
  tr#table-head {
    color: #e0e0e0;
  }
  h6#customer-heading {
    transform: translateY(30px);
  }
  #searchbtn {
    padding: $searchbtnPadding;
    font-size: $searchbtnFontSize;
    margin: $searchbtnMargin;
  }
  #customer-search {
    padding: $searchbarPadding;
    font-size: $searchbarFontSize;
  }
  div#search {
    display: $displayFlex;
    flex-wrap: $flexWrap;
    margin-top: 40px;
    justify-content: space-between;
  }
  td.MuiTablePagination-root {
    padding: 0 20px 0 0;
  }
}

a {
  color: $black;
  text-decoration: $textDec;
}
a:hover {
  color: $tableContentHover;
}
